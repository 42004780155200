import { Fragment } from "react";

declare global {
  interface Window {
    lintrk: (option: string, options: Record<string, unknown>) => void;
  }
}

const LinkedInAnalytics = ({
  linkedInPartnerId,
}: {
  linkedInPartnerId: string | null;
}) =>
  process.env.NODE_ENV === "production" && linkedInPartnerId ? (
    <Fragment>
      <script
        async
        dangerouslySetInnerHTML={{
          __html: `
          _linkedin_partner_id = '${linkedInPartnerId}';
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
          window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          `,
        }}
      />
      <script
        async
        dangerouslySetInnerHTML={{
          __html: `
          (function(l) {
            if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
            window.lintrk.q=[]}
            var s = document.getElementsByTagName('script')[0];
            var b = document.createElement('script');
            b.type = 'text/javascript';b.async = true;
            b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
            s.parentNode.insertBefore(b, s);})(window.lintrk);
            `,
        }}
      />
    </Fragment>
  ) : null;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const sendLinkedInEvent = ({ name }: { name: "sign-up" }): void => {
  if (process.env.NODE_ENV === "production" && window.lintrk) {
    window.lintrk("track", { conversion_id: 12287250 });
  }
};

export { LinkedInAnalytics, sendLinkedInEvent };
